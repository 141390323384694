<template>
  <div class="justify-content-center m-0 row">
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 p-0">
      <slot name="layout"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout",
};
</script>
