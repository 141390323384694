<template>
  <div class="adminFirstTop mb-4">
    <div class="d-flex justify-content-between buttonRadius1">
      <div class="fontSizeL iconColor">TVL</div>
      <!-- 위치수정 -->

      <div class="d-flex justify-content-center flex-column">
        <b-spinner v-if="TVL === null"></b-spinner>
        <div v-else>
          <div class="fontSizeM bottomMarginm">
            <strong>DLP</strong> {{ balance }}
          </div>
          <div class="text-end iconColor3 bottomMarginm fontSizeSmB">
            = $ {{ TVL }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tvl",
  props: {
    TVL: String,
    balance: String,
  },
};
</script>
<style scoped>
.adminFirstTop {
  margin-top: 2.5rem;
}
.fontSizeL {
  font-size: 55px;
  font-weight: 900;
}
.fontSizeM {
  font-size: 23px;
  font-weight: 700;
}

.bottomMarginm {
  margin-bottom: -6px;
}
@media screen and (max-width: 768px) {
  .adminFirstTop {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 576px) {
  .fontSizeL {
    font-size: 48px;
    font-weight: 900;
  }
  .fontSizeM {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
