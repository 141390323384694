import { useContractStore } from "../stores/contractView";
import { useAccountStore } from "../stores/account";
import {
  // getTokenContract,
  getTokenContract1,
  // getMembershipContract,
  getMembershipContract1,
  getRockContract1,
  getLotteryContract1,
} from "./contractData";

const beforeTx = async (select) => {
  const contractStore = useContractStore();
  const accountStore = useAccountStore();

  contractStore.overRay(true, true);
  const wallet = sessionStorage.getItem("wallet");
  let contract;
  //////메타마스크는 트랜잭션 보낼 때 지갑에서(윈도우.이더리움에서) 받은 주소로 요청해야됨
  if (select) {
    contract = await getMembershipContract1(wallet);
  } else {
    contract = await getTokenContract1(wallet);
  }
  return [contractStore.overRay, accountStore.account, wallet, contract];
};

export const sendRockTx = async (account, num, bet) => {
  const contract = await getRockContract1();
  try {
    const result = await contract.methods.rock(num, bet).send({
      from: account,
      gas: "8500000",
      gasPrice: 25000000000,
    });
    return { state: true, data: result };
  } catch (error) {
    console.log(error);
    return { state: false, data: null };
    // result[0](false); 오버레이
  }
};
export const sendLotteryEnterTx = async (account) => {
  const contract = await getLotteryContract1();
  try {
    const result = await contract.methods.enter().send({
      from: account,
      gas: "8500000",
      gasPrice: 25000000000,
    });
    return { state: true, data: result };
  } catch (error) {
    console.log(error);
    return { state: false, data: null };
    // result[0](false); 오버레이
  }
};

//토큰 사용 승인 요청
export const approveTx = async (spender) => {
  const result = await beforeTx(false);
  if (result[2] === "true") {
    await result[3].methods
      //increaseAapprovepproval
      .approve(spender, "30000000000000000000000000")
      .send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("error", (err) => {
        console.log(err);
        result[0](false, true);
      });
  } else {
    try {
      await result[3].methods
        //increaseAapprovepproval
        .approve(spender, "30000000000000000000000000")
        .send({
          from: result[1],
          gas: "8500000",
          gasPrice: 25000000000,
        });
    } catch (error) {
      console.log(error);
      result[0](false, true);
    }
  }
  result[0](false, true);
  return result[2];
};

//스테이킹
export const beforeDepositTx = async (grade, joinMembership) => {
  const result = await beforeTx(true);
  let transactionHash;
  if (result[2] === "true") {
    await result[3].methods
      /////
      .deposit(grade, joinMembership)
      .send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("transactionHash", (hash) => {
        transactionHash = hash;
      })
      .on("error", (err) => {
        console.log(err);
        result[0](false, true);
      });
  } else {
    try {
      const result1 = await result[3].methods
        .deposit(grade, joinMembership)
        .send({
          from: result[1],
          gas: "8500000",
          gasPrice: 25000000000,
        });
      transactionHash = result1.senderTxHash;
    } catch (error) {
      console.log(error);
      result[0](false, true);
    }
  }
  return [true, transactionHash, result[2]];
  //   this.deposit(transactionHash, wallet);
};

// 스테이킹된 돈 빼내기
export const beforeWithdrawalTx = async () => {
  const result = await beforeTx(true);
  let transactionHash;
  if (result[2] === "true") {
    await result[3].methods
      .withdrawal()
      .send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("transactionHash", (hash) => {
        transactionHash = hash;
      })
      .on("error", (err) => {
        console.log(err);
        result[0](false, true);
      });
  } else {
    try {
      const result1 = await result[3].methods.withdrawal().send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      });
      transactionHash = result1.senderTxHash;
    } catch (error) {
      console.log(error);
      result[0](false, true);
    }
  }
  return [true, transactionHash, result[2]];
};

export const beforeWithdrawal24Tx = async () => {
  const result = await beforeTx(true);
  let transactionHash;
  if (result[2] === "true") {
    await result[3].methods
      .withdrawal24()
      .send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("transactionHash", (hash) => {
        transactionHash = hash;
      })
      .on("error", (err) => {
        console.log(err);
        result[0](false, true);
      });
  } else {
    try {
      const result1 = await result[3].methods.withdrawal24().send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      });
      transactionHash = result1.senderTxHash;
    } catch (error) {
      console.log(error);
      result[0](false, true);
    }
  }
  return [true, transactionHash, result[2]];
};

export const stakingClaimTx = async () => {
  const result = await beforeTx(true);
  let transactionHash;
  if (result[2] === "true") {
    await result[3].methods
      .claim()
      .send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("transactionHash", (hash) => {
        transactionHash = hash;
      })
      .on("error", (err) => {
        console.log(err);
        result[0](false, true);
      });
  } else {
    try {
      const result1 = await result[3].methods.claim().send({
        from: result[1],
        gas: "8500000",
        gasPrice: 25000000000,
      });
      transactionHash = result1.senderTxHash;
    } catch (error) {
      console.log(error);
      result[0](false, true);
    }
  }
  return [true, transactionHash, result[2]];
};

//--------------------- 트랜잭션 발생 -------------------------
