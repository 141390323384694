<template>
  <img
    class="pointer"
    src="../assets/img/contact_support_opsz20.svg"
    alt="question"
  />
</template>

<script>
export default {
  name: "Question",
};
</script>
