<template>
  <div class="mt-5 mb-4">
    <div class="mb-3">
      <span class="fs-6"
        ><strong>{{ $t("message.newFourth16") }}</strong></span
      >
    </div>
    <div>
      <div>{{ $t("message.newFourth17") }}{{ rewardBalanceOf }} DLP</div>
      <slot name="maxRate"></slot>

      <div class="d-flex align-items-end mb-5">
        <div>{{ $t("message.newFourth18") }}{{ rewardAllow }} DLP</div>
        <button
          type="button"
          class="btn-sm limitIncrease mx-2"
          @click="$emit('adminApprove')"
        >
          UP
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RewardAddressSet",
  emits: ["adminApprove"],
  props: {
    rewardBalanceOf: String,
    rewardAllow: String,
  },
};
</script>
<style scoped>
.limitIncrease {
  padding: 2px 4px !important;
  color: #ffde5f24;
  font-size: 13px !important;
  font-weight: 500;
  background-color: #ffde5f;
  border: 1px solid #ffde5f;
  border-radius: 8px !important;
}
@media screen and (max-width: 576px) {
  .limitIncrease {
    font-size: 11px !important;
  }
}
</style>
