<template>
  <div class="my-5">
    <div class="d-flex justify-content-between buttonRadius1">
      <span class="fs-6"
        ><strong>{{ $t("message.newFourth3") }}</strong></span
      >
      <BasicButton
        @click.native="notionSetting()"
        :text="$t('message.newFourth4')"
      />
    </div>

    <b-row class="mx-0 mt-3">
      <b-col cols="12" class="px-0">
        <b-form-datepicker v-model="notificationDate"></b-form-datepicker>
      </b-col>

      <BasicInputText
        @updateParentInputText="notificationText = $event.target.value"
        :title="notificationText"
        :text="$t('message.newFourth5')"
      />
      <BasicInputText
        @updateParentInputText="notificationLink = $event.target.value"
        :title="notificationLink"
        :text="$t('message.newFourth6')"
      />
      <b-col cols="12" class="px-0 mt-3">
        <div class="d-flex justify-content-between">
          <div>{{ $t("message.newFourth7") }}</div>
          <div>
            <img
              class="pointer"
              @click="(showNotion = true), notionUpdate(seletedNotion)"
              src="../../assets/img/edit_black_18dp.svg"
              alt="update"
            />
            <img
              class="pointer"
              @click="notionDel(seletedNotion)"
              src="../../assets/img/delete_black_18dp.svg"
              alt="delete"
            />
          </div>
        </div>
        <hr />

        <b-form-radio-group
          id="radio-group-2"
          v-model="seletedNotion"
          plain
          stacked
        >
          <div v-if="notionStore.lengthN <= 0">
            <div>{{ $t("message.newFourth8") }}</div>
          </div>
          <b-form-radio
            v-else
            v-for="(list, index) in notionStore.notification"
            :value="notionStore.check(index)"
            :key="index"
          >
            <div class="d-flex justify-content-between smText1">
              <div>
                {{ list.main }}
              </div>
              <div>
                {{ list.date.slice(5, 10) }}
              </div>
            </div>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <!-- 공지 모달 -->
    <b-modal v-model="showNotion" hide-footer hide-header centered>
      <div class="absoluteRight pointer" @click="showNotion = false">
        <BIconX scale="1.5" variant="secondary"></BIconX>
      </div>
      <h4 class="text-center">
        {{ $t("message.newFourth9") }}
      </h4>
      <b-row class="mx-0 mt-3">
        <b-col cols="12" class="px-0">
          <b-form-datepicker
            v-model="notificationDateUpdate"
          ></b-form-datepicker>
        </b-col>
        <BasicInputText
          @updateParentInputText="notificationTextUpdate = $event.target.value"
          :title="notificationTextUpdate"
          :text="''"
        />
        <BasicInputText
          @updateParentInputText="notificationLinkUpdate = $event.target.value"
          :title="notificationLinkUpdate"
          :text="''"
        />
      </b-row>
      <div class="buttonRadius1 text-center mt-3">
        <b-button @click="notionUpdateServer()" class="me-1 buttonColor">{{
          $t("message.newFourth10")
        }}</b-button>
      </div>
    </b-modal>
    <!-- 공지 모달 끝 -->
  </div>
</template>

<script>
import { BIconX } from "bootstrap-vue";
import { api } from "../../control/axios";
import { check } from "../../control/admin";
import { useAccountStore } from "../../stores/account";
import { useNotionStore } from "../../stores/notion";

import BasicButton from "../basicUse/BasicButton.vue";
import BasicInputText from "../basicUse/BasicInputText.vue";
export default {
  name: "NotionUp",
  components: {
    BasicButton,
    BasicInputText,
    BIconX,
  },
  emits: ["showMsgBoxTwo"],
  setup() {
    const accountStore = useAccountStore();
    const notionStore = useNotionStore();
    return { accountStore, notionStore };
  },
  data() {
    return {
      api,
      check,
      notificationText: null,
      notificationDate: null,
      notificationLink: null,
      notificationTextUpdate: null,
      notificationDateUpdate: null,
      notificationLinkUpdate: null,
      seletedNotion: null,
      showNotion: false,
    };
  },
  methods: {
    async axiosUpdate() {
      const checkAddress = await this.check(this.accountStore.account);
      if (checkAddress) {
        try {
          this.api("/api/notification", "post", {
            param: [
              this.notionStore.notification,
              this.notionStore.notificationNumList,
              this.accountStore.account,
            ],
          }).then((result) => {
            this.notionStore.notificationCopy(result.c);
            this.notionStore.numListCopy(result.updateA);
            this.$emit("showMsgBoxTwo", this.$t("message.newFourth11"));
          });
        } catch (error) {
          this.$emit("showMsgBoxTwo", this.$t("message.newFourth12"));
        }
      } else {
        alert("admin only");
      }
    },

    async notionDel(index) {
      const checkAddress = await this.check(this.accountStore.account);
      if (checkAddress) {
        const delte = this.seeIndex(index);
        this.notionStore.notificationCut(delte);
        this.notionStore.numListCut1(index);
        this.axiosUpdate();
      } else {
        alert("admin only");
      }
    },

    seeIndex(index) {
      for (var i = 0; i < this.notionStore.lengthN; i++) {
        if (this.notionStore.check(i) == index) {
          return i;
        }
      }
    },

    notionUpdate(index) {
      this.lookNum = this.seeIndex(index);
      const num = this.lookNum;
      this.notificationTextUpdate = this.notionStore.getMain(num);
      this.notificationDateUpdate = this.notionStore.getDate(num);
      this.notificationLinkUpdate = this.notionStore.getLink(num);
    },

    notionUpdateServer() {
      const num = this.lookNum;
      this.notionStore.updateMain(num, this.notificationTextUpdate);
      this.notionStore.updateDate(num, this.notificationDateUpdate);
      this.notionStore.updateLink(num, this.notificationLinkUpdate);

      this.axiosUpdate();

      this.showNotion = false;
    },
    notionSetting() {
      if (this.notionStore.lengthN < 10) {
        if (
          this.notificationText != null &&
          this.notificationDate != null &&
          this.notificationLink != null
        ) {
          const firstNum = this.notionStore.notificationNumList[0];
          this.notionStore.numListCut();
          const date = {
            main: this.notificationText,
            date: this.notificationDate,
            link: this.notificationLink,
            num: firstNum,
          };
          this.notionStore.notificationUp(date);
          this.axiosUpdate();
          this.notificationText = null;
          this.notificationDate = null;
          this.notificationLink = null;
        } else {
          this.$emit("showMsgBoxTwo", this.$t("message.newFourth13"));
        }
      } else {
        this.$emit("showMsgBoxTwo", this.$t("message.newFourth14"));
      }
    },
  },
};
</script>
