<template>
  <div class="mt-4 mb-5">
    <div>
      <input
        type="file"
        class="form-control"
        accept="image/png,image/jpeg"
        @change="uploadFile($event.target.files)"
      />
    </div>
    <b-row>
      <b-col v-for="(m, n) in bannerStore.bannerList" :key="n">
        <div class="position-relative">
          <div class="text-end pointer" @click="deleteImage(m)">X</div>
          <b-img thumbnail :src="`${call}/api/download/${m}`" alt="banner" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { api } from "../../control/axios";
import { check } from "../../control/admin";
import { useAccountStore } from "../../stores/account";
import { useBannerStore } from "../../stores/banner";

export default {
  name: "Banner",
  created() {
    this.call = process.env.VUE_APP_BASEURL;
  },
  setup() {
    const accountStore = useAccountStore();
    const bannerStore = useBannerStore();
    return { accountStore, bannerStore };
  },
  data() {
    return {
      api,
      check,
      list3: [],
      admin: null,
    };
  },
  methods: {
    //t_basic 에 이미지 업로드
    async uploadFile(files) {
      let name = "";
      let data = null;
      if (files) {
        name = files[0].name;
        if (!this.nameCheck(name)) {
          return alert("Symbols and spaces cannot be used in filenames.");
        }
        data = await this.base64(files[0]);
      }
      const checkAddress = await check(this.accountStore.account);

      if (files[0].size > 1000000) {
        alert(this.$t("message.help12"));
      } else if (checkAddress) {
        const { error } = await this.api("/api/upload", "post", {
          param: [name, data, this.accountStore.account],
        });
        if (error) {
          console.log(error);
          return alert(this.$t("message.help13"));
        }
        alert(this.$t("message.help14"));
        setTimeout(async () => {
          await this.bannerStore.setting();
        }, 1000);
      } else {
        alert("admin only");
      }
    },

    async deleteImage(id) {
      const date = this.list3.indexOf(id);
      const checkAddress = await check(this.accountStore.account);
      if (checkAddress) {
        const { error } = await this.api("/api/banner", "delete", {
          param: [id, this.accountStore.account],
          date,
        });

        if (error) {
          // 없어도 되나?
          await this.bannerStore.setting();
          return alert(this.$t("message.help15"));
        }

        alert(this.$t("message.help16"));
        await this.bannerStore.setting();
      } else {
        alert("admin only");
      }
    },

    base64(file) {
      return new Promise((resolve) => {
        var a = new FileReader();
        a.onload = (e) => resolve(e.target.result);
        a.readAsDataURL(file);
      });
    },

    nameCheck(fileName) {
      const regex = /[`~!@#$%^&*()-_=+{}|;:'"<>,./? ]/g;
      if (fileName.match(regex)) {
        return false;
      }
      return true;
    },
  },
};
</script>
