<template>
  <!-- 관리자 페이지 -->
  <Layout>
    <div slot="layout">
      <section>
        <AdminTvl :TVL="adminStore.TVL" :balance="adminStore.balance" />
      </section>
      <hr />
      <section>
        <AdminRewardAddressSet
          :rewardAllow="adminStore.rewardAllow"
          :rewardBalanceOf="adminStore.rewardBalanceOf"
          @adminApprove="adminApprove"
        >
          <div slot="maxRate">
            <span>{{ $t("message.help26") }}</span
            ><QuestionMark
              @click.native="showMsgBoxTwo($t('message.help25'))"
            />
            <span> : {{ adminStore.readyForInterest }} DLP</span>
          </div>
        </AdminRewardAddressSet>
      </section>
      <hr />
      <!-- 사용자 등급별 조회 -->
      <section>
        <!-- 등급 누르면 해당 등급자들 좌르륵 -->
        <div class="mt-5 mb-4">
          <div class="mb-3">
            <span class="fs-6"
              ><strong>{{ $t("message.fourth4") }}</strong></span
            >
          </div>
          <div class="d-flex justify-content-between buttonRadius1">
            <b-form-select
              v-model="grade1"
              class="adminSelectBar borderRadius"
              :options="options2"
            ></b-form-select>
            <!-- 등급 분류가 아직 끝나지 않았을 때 -->
            <BasicButton
              v-if="adminStore.addressToDateTF == false"
              disabled
              text="wait..."
            />
            <!-- 등급 분류가 아직 끝나지 않았을 때 끝 -->
            <!-- 등급 분류가 끝났을 때 -->
            <BasicButton
              v-else
              @click.native="seeGrade()"
              :text="$t('message.fourth5')"
            />
          </div>
          <!-- 등급 분류가 끝났을 때 끝 -->
          <!-- 조회 결과 없음 -->

          <!-- v-else -->
          <!-- 조회 결과  -->
          <b-table
            id="my-table"
            class="mt-3 text-center smText"
            :items="user"
            :per-page="10"
            :current-page="currentPage"
            table-variant="white"
            responsive="true"
          ></b-table>
          <!-- 조회 결과 끝 -->
        </div>
        <b-pagination
          class="mb-5"
          v-model="currentPage"
          pills
          :total-rows="rows"
          :per-page="10"
          aria-controls="my-table"
        >
          <template #page="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <i v-else class="adminPageElse">{{ page }}</i>
          </template>
        </b-pagination>
        <!-- 사용자 등급별 조회 끝 -->
      </section>
      <hr />
      <!-- 사용자 조회 -->
      <section>
        <div class="my-5">
          <div class="d-flex justify-content-between buttonRadius1">
            <span class="fs-6"
              ><strong>{{ $t("message.newFourth2") }}</strong></span
            >
            <div>
              <BasicButton
                @click.native="addressToGrade()"
                :text="$t('message.fourth5')"
              />
            </div>
          </div>

          <input
            type="text"
            @input="keyCatch()"
            :value="customerAddress"
            :placeholder="$t('message.fourth8')"
            class="sizee rowBorder borderRadius px-2 mt-3"
          />
          <b-spinner v-if="addressToGradeWait"></b-spinner>
          <b-card-text v-else class="mt-3">{{ userGrade }}</b-card-text>
        </div>
      </section>
      <!-- 사용자 조회 끝 -->
      <hr class="mt-5" />
      <!-- 공지사항 등록 -->
      <section>
        <AdminNotionUp @showMsgBoxTwo="showMsgBoxTwo" />
      </section>
      <!-- 공지사항 등록 끝 -->
      <hr />
      <!-- 배너 조회 -->
      <section>
        <div class="mt-5">
          <span class="fs-6"
            ><strong>{{ $t("message.newFourth21") }}</strong></span
          >
          <div class="mt-3 fontSizeSmB iconColor1">
            {{ $t("message.mef5") }}
          </div>
        </div>
        <AdminBanner />
      </section>
      <!-- 배너 끝 -->
    </div>
  </Layout>
  <!-- 관리자 페이지 끝 -->
</template>
<script>
import modalData from "../assets/data/modal.json";
import QuestionMark from "../components/Question.vue";
import AdminTvl from "../components/admin/Tvl.vue";
import AdminRewardAddressSet from "../components/admin/RewardAddressSet.vue";
import AdminNotionUp from "../components/admin/NotionUp.vue";
import AdminBanner from "../components/admin/Banner.vue";
import BasicButton from "../components/basicUse/BasicButton.vue";
import Layout from "../components/basicUse/Layout.vue";
import { switchGrade } from "../control/grade";
import { approveTx } from "../control/sendTx";
import { getMembershipContract } from "../control/contractData";

import { useAccountStore } from "../stores/account";
import { usePageStore } from "../stores/nextPage";
import { useAdminStore } from "../stores/adminCheck";
import { useContractStore } from "../stores/contractView";

export default {
  name: "Admin",
  components: {
    QuestionMark,
    AdminTvl,
    AdminRewardAddressSet,
    AdminNotionUp,
    AdminBanner,
    BasicButton,
    Layout,
  },
  data() {
    return {
      switchGrade,
      modalData,
      getMembershipContract,
      approveTx,
      grade: null,
      grade1: null,
      options2: [
        { value: null, text: "-- Please select a grade --" },
        { value: "3", text: "Tropical Sun" },
        { value: "2", text: "Cherry Red" },
        { value: "1", text: "Ocean Blue" },
        { value: "999", text: "탈퇴자" },
      ],
      customerAddress: null,
      userGrade: null,
      user: [],
      currentPage: 1,
      noti: null,
      addressToGradeWait: false,
    };
  },
  computed: {
    rows() {
      return this.user.length;
    },
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const adminStore = useAdminStore();
    const contractStore = useContractStore();

    return { accountStore, pageStore, adminStore, contractStore };
  },
  async mounted() {
    if (this.accountStore.account !== "") {
      // 방문한적 없음
      if (!this.pageStore.adminFV) {
        this.pageStore.setAdminVisit();
        await this.adminStore.adminCall();
      }
    }
    this.accountStore.$subscribe(async () => {
      if (this.$route.path === "/admin") {
        if (!this.pageStore.adminFV) {
          if (this.accountStore.account !== "") {
            this.pageStore.setAdminVisit();
            await this.adminStore.adminCall();
          }
        }
      }
    });
  },
  methods: {
    adminApprove() {
      if (this.accountStore.account == this.adminStore.rewardPaymentAccount) {
        // this.approve(this.testMembership);
        this.showMsgBoxOne(this.$t("message.newFourth20"));
      } else {
        this.showMsgBoxTwo(this.$t("message.newFourth19"));
      }
    },

    // 해당 등급에 스테이깅 중인 유저 보여주기위해
    seeGrade() {
      const grade = Number(this.grade1);
      this.user = this.adminStore.resultGrade[grade];
    },

    //계정 주소로 등급 확인
    async addressToGrade() {
      const wallet = sessionStorage.getItem("wallet");
      const contract = await this.getMembershipContract(wallet);
      try {
        this.addressToGradeWait = true;
        const userGrade1 = await contract.methods
          .userInfos(this.customerAddress)
          .call();
        this.userGrade = this.switchGrade(userGrade1.grade);
        this.addressToGradeWait = false;
      } catch {
        this.showMsgBoxTwo(this.$t("message.scriptMeg8"));
        this.addressToGradeWait = false;
      }
    },

    keyCatch() {
      const regex = /[<>&]/g;
      event.target.value = event.target.value.replace(regex, "");
      this.customerAddress = event.target.value;
    },

    // 모달창 ------------------------------------------------
    // this$bvModal을 못찾아서 여기둠
    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");
      this.$bvModal.msgBoxOk(msg, modal);
    },

    showMsgBoxOne(msg) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");
      modal.cancelTitle = this.$t("message.mef8");
      modal.cancelVariant = "danger";
      this.$bvModal.msgBoxConfirm(msg, this.modalData).then(async () => {
        const wallet = await this.approveTx(this.membership);
        const allow = await this.allowance(
          this.membership,
          this.accountStore.account,
          wallet
        );
        this.contractStore.setRewardAllow(allow);
      });
    },
  },
};
</script>
<style scoped>
.adminSelectBar {
  width: 60%;
  color: #888888;
  border-width: 2px;
  border-color: rgb(222, 226, 230);
}
.adminPageElse {
  color: #ffde5f !important;
  font-style: normal !important;
}
</style>
